import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import NextButton from '../../shared/button/next';
import ResetButton from '../../shared/button/resetButton';
import CompanyProfilePhoto from '../../profilePages/components/companyProfilePhoto';
import GigsPhoto from '../../shared/modals/gigsImageModal';
import * as companyAPI from '../../api/companies';
import 'bootstrap/dist/css/bootstrap.css';
import '../css/createJob.css';
import '../../shared/css/typography.css';
import { toast } from 'react-toastify';
import company from '../../assets/SnapBetaLogo2.svg';
import { useJobForm } from '../../context/jobContext';
import { useAuth } from '../../context/authContext';
import { getImageByUrl } from '../../shared/images';

export const JobStepOneBounty = ({ nextStep, prevStep, resetStep }) => {
  const {
    touched,
    errors,
    handleChange,
    values,
    handleBlur,
    validateForm,
    resetJobForm,
    setFieldTouched,
    setFieldValue,
  } = useJobForm();
  const { auth } = useAuth();
  const [gigLogo, setGigLogo] = useState(null);

  useEffect(() => {
    let didCancel = false;
    let company = null;
    const fetchCompany = async () => {
      try {
        company = await companyAPI.getCompany(auth.company._id);
      } catch (error) {
        throw new Error('Error fetching company: ', error);
      }

      if (!didCancel) {
        setGigLogo(getImageByUrl(company.company.companyLogo));
      }
      if (values.logo) {
        setGigLogo(getImageByUrl(values.logo));
      }
    };

    fetchCompany();
    return () => {
      didCancel = true;
    };
  }, []);

  const handleRemove = async () => {
    try {
      setFieldValue('bountyLogo', auth.company.companyLogo);
    } catch (error) {}
    setGigLogo(getImageByUrl(auth.company.companyLogo));
  };

  const handleSave = async (fileName) => {
    try {
      setFieldValue('bountyLogo', fileName);
    } catch (error) {}
  };

  const next = async (e) => {
    const currentErrors = await validateForm();
    e.preventDefault();
    if (currentErrors.title || currentErrors.taskDescription) {
      setFieldTouched('title');
      setFieldTouched('taskDescription');
      return;
    }
    nextStep();
  };

  const back = (e) => {
    e.preventDefault();
    prevStep();
  };

  const reset = async (e) => {
    e.preventDefault();
    resetJobForm();
    resetStep();
  };

  return (
    <Container>
      <Row>
        <Col xs={12} md={2} className="company_photo-in-gig-flow">
          <GigsPhoto
            className="comp-profile-photo"
            gigLogo={gigLogo}
            setGigLogo={setGigLogo}
            handleSave={handleSave}
            handleRemove={handleRemove}
          />
        </Col>
        <Col xs={12} md={10}>
          <p className="grey-bounty-setup-header">
            Let&apos;s start creating your first Job so people can help you with
            any of your current business needs by giving it an
            easy-to-understand title and description.
          </p>
        </Col>
      </Row>
      <Form>
        <Form.Group className="mb-4" controlId="stepOneGroup1">
          <Form.Label className="form_label bounty-type-label ps-0">
            Job Title <span className="required">*</span>
          </Form.Label>
          <Form.Control
            className="gig-form-field"
            name="title"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            isInvalid={!!errors.title}
          />
          {touched.title && errors.title && (
            <Form.Control.Feedback type="invalid">
              {errors.title}
            </Form.Control.Feedback>
          )}
          <Form.Text className="text-muted">
            <i>Examples:</i> &quot;Senior Sales Representative&quot;,
            &quot;Associate Product Manager&quot;, or &quot;Health
            Educator&quot;
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-4" controlId="stepOneGroup2">
          <Form.Label className="form_label bounty-type-label ps-0">
            Job Description <span className="required">*</span>
          </Form.Label>
          <Form.Control
            className="gig-form-field"
            name="taskDescription"
            as="textarea"
            rows={4}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.taskDescription}
            isInvalid={!!errors.taskDescription}
          />
          {touched.taskDescription && errors.taskDescription && (
            <Form.Control.Feedback type="invalid">
              {errors.taskDescription}
            </Form.Control.Feedback>
          )}
          <Form.Text className="text-muted">
            <i>Example:</i> &quot;We would like a sales representative with good
            people skills, product marketing and sales techniques and
            negotiation skills to join our growing team to help drive revenue.
            Basic computing skills are needed to use our tools, including our
            CRM system, which allows us to efficiently track and manage customer
            interactions, leads and other opportunities. Being able to think
            quickly on your feet is a plus.&quot;
          </Form.Text>
        </Form.Group>
        <Row>
          <Col xs={12} md={5}>
            <div className="mt-4">
              <ResetButton handleClick={reset} />
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="float-end mt-4">
              <NextButton
                onClick={next}
                type="button"
                disabled={errors.taskDescription || errors.title}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default JobStepOneBounty;
