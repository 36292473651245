/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import NextButton from '../../shared/button/next';
import ResetButton from '../../shared/button/resetButton';
import CompanyProfilePhoto from '../../profilePages/components/companyProfilePhoto';
import GigsPhoto from '../../shared/modals/gigsImageModal';
import * as companyAPI from '../../api/companies';
import 'bootstrap/dist/css/bootstrap.css';
import '../css/createBounty.css';
import '../../shared/css/typography.css';
import { toast } from 'react-toastify';
import company from '../../assets/SnapBetaLogo2.svg';
import { useGigForm } from '../../context/gigContext';
import { useAuth } from '../../context/authContext';
import { getImageByUrl } from '../../shared/images';

export const StepOneBounty = ({ nextStep, prevStep, resetStep }) => {
  const {
    touched,
    errors,
    handleChange,
    values,
    handleBlur,
    validateForm,
    resetGigForm,
    setFieldTouched,
    setFieldValue,
  } = useGigForm();
  const { auth } = useAuth();
  const [gigLogo, setGigLogo] = useState(null);

  useEffect(() => {
    let didCancel = false;
    let company = null;
    const fetchCompany = async () => {
      try {
        company = await companyAPI.getCompany(auth.company._id);
      } catch (error) {
        throw new Error('Error fetching company: ', error);
      }

      if (!didCancel) {
        setGigLogo(getImageByUrl(company.company.companyLogo));
      }
      if (values.logo) {
        setGigLogo(getImageByUrl(values.bountyLogo));
      }
    };

    fetchCompany();
    return () => {
      didCancel = true;
    };
  }, []);

  const handleRemove = async () => {
    try {
      setFieldValue('bountyLogo', auth.company.companyLogo);
    } catch (error) {}
    setGigLogo(getImageByUrl(auth.company.companyLogo));
  };

  const handleSave = async (fileName) => {
    try {
      setFieldValue('bountyLogo', fileName);
    } catch (error) {}
  };

  const next = async (e) => {
    const currentErrors = await validateForm();
    e.preventDefault();
    if (currentErrors.title || currentErrors.taskDescription) {
      setFieldTouched('title');
      setFieldTouched('taskDescription');
      return;
    }
    nextStep();
  };

  const back = (e) => {
    e.preventDefault();
    prevStep();
  };

  const reset = async (e) => {
    e.preventDefault();
    resetGigForm();
    resetStep();
  };

  return (
    <Container>
      <Row>
        <Col xs={12} md={2} className="company_photo-in-gig-flow">
          <GigsPhoto
            className="comp-profile-photo"
            gigLogo={gigLogo}
            setGigLogo={setGigLogo}
            handleSave={handleSave}
            handleRemove={handleRemove}
          />
        </Col>
        <Col xs={12} md={10}>
          <p className="grey-bounty-setup-header">
            Let&apos;s start creating your first gig so people can help you with
            any of your current business needs by giving it an
            easy-to-understand title and description.
          </p>
        </Col>
      </Row>
      <Form>
        <Form.Group className="mb-4" controlId="stepOneGroup1">
          <Form.Label className="form_label bounty-type-label ps-0">
            Gig Title <span className="required">*</span>
          </Form.Label>
          <Form.Control
            className="gig-form-field"
            name="title"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            isInvalid={!!errors.title}
          />
          {touched.title && errors.title && (
            <Form.Control.Feedback type="invalid">
              {errors.title}
            </Form.Control.Feedback>
          )}
          <Form.Label className="text-muted">
            <i>Examples:</i> &quot;Develop a web3 wallet browser extension for
            my cryptocurrency&quot;, &quot;Design a brand style guide for my
            company&quot;, or &quot;Help design our serverless
            architecture&quot;
          </Form.Label>
        </Form.Group>
        <Form.Group className="mb-4" controlId="stepOneGroup2">
          <Form.Label className="form_label bounty-type-label ps-0">
            Gig Description <span className="required">*</span>
          </Form.Label>
          <Form.Control
            className="gig-form-field"
            name="taskDescription"
            as="textarea"
            rows={4}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.taskDescription}
            isInvalid={!!errors.taskDescription}
          />
          {touched.taskDescription && errors.taskDescription && (
            <Form.Control.Feedback type="invalid">
              {errors.taskDescription}
            </Form.Control.Feedback>
          )}
          <Form.Label className="text-muted">
            <i>Example:</i> &quot;We would like to implement a withdrawal fee.
            When a staking user withdraws their funds from the pool or claims
            rewards, we would like to implement a flat fee of 0.5% that goes to
            an address of choice. This would involve, in part, adding an
            off-chain transaction code that produces a new utxo to this new
            address.&quot;
          </Form.Label>
        </Form.Group>
        <Row>
          <Col xs={12} md={5}>
            <div className="mt-4">
              <ResetButton handleClick={reset} />
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="float-end mt-4">
              <NextButton
                onClick={next}
                type="button"
                disabled={errors.taskDescription || errors.name}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default StepOneBounty;
