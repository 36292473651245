/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import NextButton from '../../shared/button/next';
import BackButton from '../../shared/button/back';
import ResetButton from '../../shared/button/resetButton';
import 'bootstrap/dist/css/bootstrap.css';
import '../css/createBounty.css';
import '../../shared/css/typography.css';

import { useGigForm } from '../../context/gigContext';
import { useState } from 'react';

export const StepThreeBounty = ({ nextStep, prevStep, resetStep }) => {
  const {
    touched,
    errors,
    handleChange,
    values,
    handleBlur,
    validateForm,
    setFieldTouched,
    resetGigForm,
  } = useGigForm();
  const [makePrivate, setMakePrivate] = useState(false);
  // const [isHiring, setIsHiring] = useState(values.isHiring);

  const next = async (e) => {
    const currentErrors = await validateForm();
    e.preventDefault();
    if (currentErrors.importantLink) {
      setFieldTouched('importantLink');
      return;
    }
    let a = JSON.stringify(values);
    nextStep();
  };

  const back = (e) => {
    e.preventDefault();
    prevStep();
  };

  useEffect(() => {
    if (values._id) {
      setFieldTouched('importantLink');
    }
  }, []);

  const reset = async (e) => {
    e.preventDefault();
    resetGigForm();
    resetStep();
  };
  return (
    <Container>
      <Row>
        <p className="grey-bounty-setup-header">
          Add specific links and select type of gig
        </p>
      </Row>
      <Form>
        <Form.Group className="mb-4" controlId="steptwoGroup1">
          <Form.Label className="form_label bounty-type-label ps-0">
            Reference Links <span className="required">*</span>
          </Form.Label>
          <Form.Control
            className="gig-form-field"
            name="importantLink"
            as="textarea"
            rows={3}
            placeholder="Add any links needed as a reference to work on your gig."
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.importantLink}
            isInvalid={!!errors.importantLink}
          />
          {touched.importantLink && errors.importantLink && (
            <Form.Control.Feedback type="invalid">
              {errors.importantLink}
            </Form.Control.Feedback>
          )}
          <Form.Text className="text-muted">
            <i>Example:</i> https://github.com/snapbrillia or
            https://figma.com/snapbrillia or https://snapbrillia.com/careers
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-4" controlId="steptwoGroup2">
          <Form.Label className="form_label bounty-type-label ps-0">
            Teams or Freelancers{''}
            <span className="required">*</span>
          </Form.Label>
          <Form.Control as="div" className="remove-radio-border">
            <Form.Check
              defaultChecked={values.teamPreference === false}
              inline
              label="Team[s] & Freelancer[s]"
              name="bountyWorkPreference"
              type="radio"
              id="All"
              disabled={values.isPrivate}
              onChange={() => {
                handleChange({
                  target: { name: 'teamPreference', value: false },
                });
              }}
            />
            <Form.Check
              inline
              defaultChecked={values.teamPreference === true}
              label="Team[s]"
              name="bountyWorkPreference"
              type="radio"
              id="TeamsOnly"
              disabled={values.isPrivate}
              onChange={() => {
                handleChange({
                  target: { name: 'teamPreference', value: true },
                });
              }}
            />
          </Form.Control>
          <Form.Text className="text-muted">
            Do you want only team or allow both team and freelancer to apply to
            your gig?
          </Form.Text>
        </Form.Group>

        <Row>
          <Col xs={12} md={5}>
            <div className="mt-4">
              <ResetButton handleClick={reset} />
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="float-end mt-4">
              <BackButton onClick={back} />
              <NextButton
                onClick={next}
                type="button"
                disabled={errors.importantLink}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default StepThreeBounty;
