import React, { useState } from 'react';
import company from '../../assets/SnapBetaLogo2.svg';
import RemoveProfileImageModal from '../../shared/modals/removeProfileImageModal';
import ProfileImageModal from '../../shared/modals/profileImageModal';

const GigsPhoto = ({ gigLogo, setGigLogo, handleSave, handleRemove }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleUpdateGig = async (data) => {
    handleSave(data.url);
  };
  return (
    <div className="d-flex flex-row justify-content-center align-items-center ">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="shadow p-0 mb-3 bg-white rounded-circle company-profile">
          <img
            src={gigLogo ? gigLogo : company}
            width="400"
            height="400"
            style={{ borderRadius: '50%' }}
          />
          <div className="flex-column justify-content-center align-items-center edit-company-profile">
            <span
              style={{ border: 'none' }}
              className="flex removeReplace fw-400"
              onClick={() => handleRemove(true)}
              disabled={gigLogo === undefined || gigLogo === ''}
            >
              Remove
            </span>
            <span
              className="flex removeReplace fw-400"
              onClick={() => {
                setOpenEditModal(true);
              }}
            >
              Replace
            </span>
          </div>
          <RemoveProfileImageModal
            show={showConfirm}
            closeModal={() => setShowConfirm(false)}
            handleRemove={handleRemove}
          />
          <ProfileImageModal
            show={openEditModal}
            closeModal={() => setOpenEditModal(false)}
            handleRemove={handleRemove}
            source={gigLogo ? gigLogo : company}
            handleSave={handleSave}
            setSource={setGigLogo}
            handleUpdateGig={handleUpdateGig}
          />
        </div>
      </div>
    </div>
  );
};

export default GigsPhoto;
